@import "./variables";

/* ######## Footer Cntainer   ########### */
.footer {
  display: flex;
  flex-direction: column;
  /* ####### className="footer__blank has been created to give space left side 
of the footer component  ######## */
  &__blank {
    padding: 0 0 0 3vw;
  }
  /* Footer top consist of Header. */
  &__top {
    &_title {
      width: 100%;
      height: 24px;
      font-family: Ubuntu-Light;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.5px;
      text-align: left;
      color: var(--FooterPrimaryColor);
      padding: 0 0 3.5rem 0;
    }
  }
  /* ###### Footer Content -- Middle part of the footer! ####### */
  &__content {
    display: flex;
    flex-direction: row;
    margin: 0.7rem 0 0 0;
    justify-content: space-between;
    /* ####### Emails on the left ####### */
    &_emails {
      height: 98px;
      font-family: Ubuntu-Regular;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.92;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--FooterPrimaryColor);
      display: flex;
      flex-direction: column;
      padding: 0 8rem 0 0;
      width: 100%;
      a {
        text-decoration: none;
        display: block;
        color: rgba(0, 0, 0, 0.7);
      }
    }
    /* ####### Footer Content: Label, input, button ###### */
    &_middlepart {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      label {
        width: 153px;
        height: 37px;
        font-family: Ubuntu-Medium;
        font-size: 14px;
        font-weight: 1000;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.1px;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
      }
      input {
        width: 365px;
        height: 34px;
        padding: 0.5px;
        outline: white;
        align-items: center;
        padding: 7px 13px;
        border: solid 1px rgba(0, 0, 0, 0.12);
        background-color: var(--white);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      button {
        height: 34px;
        color: var(--white);
        border: none;
        font-family: Ubuntu-Regular;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 7px 13px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-image: linear-gradient(111deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor) 100%);
      }
    }
    /* ##### Consist of Facebook and Youtube icons  ####### */
    &_icons_container {
      width: 100%;
      justify-content: center;
      &_socialmedia {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 42px;
        opacity: 0.3;
      }
      .facebook {
        width: 9.8px;
        height: 18.3px;
        background-color: var(--FooterSocialMedBgColor);
        color: var(--black);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .linkedIn {
        width: 9.8px;
        height: 18.3px;
        background-color: var(--FooterSocialMedBgColor);
        color: var(--black);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .twitter {
        width: 9.8px;
        height: 18.3px;
        background-color: var(--FooterSocialMedBgColor);
        color: var(--black);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .youtube {
        width: 9.8px;
        height: 18.3px;
        background-color: var(--FooterSocialMedBgColor);
        color: var(--black);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  /* ##### Cokies part at the bottom ####### */
  &__bottom {
    ul {
      li {
        display: inline;
        padding: 10px;

        a {
          width: fit-content;
          font-family: "Ubuntu-Regular";
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.5px;
          color: var(--FooterSecondaryColor);
          text-decoration: none;
        }
      }
    }
  }
}

.hr-faded{
  opacity: 1 !important;
  height: 1px !important;
  background-image: linear-gradient(to right, #ed3a7f 0%, #ff6033 100%);
}

/* Responsive Classes */
@media screen and (max-width: 1024px){
  .left-heading{
    margin-left: 2rem !important;
    margin-top: 3rem !important;
  }
  .login-form{
    margin-top: 1rem !important;
    max-width: 30rem !important;
  }
}
