@import "../views/variables.scss";

.firstLoginContainer{
    width: 100vw;
    height: 100vh;

    .breadCrumbs{
        display: none !important;
    }

    .col__dx_brandname_container_Onclick_parag1.bg__myProfile, .bg__ChangePassword.col__dx_brandname_container_Onclick_parag3, .col__dx_brandname_container_Onclick_parag2.bg__admin, .col__dx_brandname_container_Onclick_hr{
        display: none;
    }

    .content{
        width: 80%;
        height: auto;
        padding: 10px;
        margin: 100px auto;
        

        .heading{
            text-align: center;
        }

        .body{
            position: relative;
        }

        .spinner{
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: #0000002f;
            z-index: 50;
            
            .spinnerItem{
                display: flex;
                align-items: center;
                height: 100%;
                z-index: 51;

                svg{
                    margin: 0 auto;
                }
            }
        }
    }

    .fadedButton{
        background-image: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
        color: #fff !important;
        margin-left: 5px;
        margin-right: 5px;
    }
    
    .fadedButton:hover, .fadedButton:active, .fadedButton:focus{
        background-image: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
        background-color: transparent;
        box-shadow: none;
    }

    .fadedText{
        background: -webkit-linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
    }

    .Button_container{
        text-align: center;
    }
    
}