@import "./variables";


.helpPage{

    a {
        text-decoration: none !important;
        color: inherit;
    }

    .cardContainer::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
        transition: width .3s;
        margin-top: 10px;
    }
    
    .cardContainer:hover::after {
        width: 100%;
        transition: width .3s;
    }

    .cardContainer{
        font-family: 'Ubuntu-Regular';
        letter-spacing: 0.4px;
        font-weight: 500;
        height: 150px;

        .cardHeading{
            font-size: 1.5rem;
            background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 900;
        }

        .cardDescription {
            font-size: 12px;
        }

        .cardIcon{
            span{
                font-size: 4rem;
                background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .accordion {
        .accordion-item{
            border: none !important;
            .accordion-body{
                background-color: rgb(236, 236, 236);
                margin-bottom: 10px;
            }
            .accordion-header{
                background-color: #fff !important;
                
                .accordion-button{
                    font-size: 1.1rem;
                    border: 1px solid rgb(236, 236, 236);;
                }

                .accordion-button:not(.collapsed){
                    background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
                    color: #fff;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                }

            }
        }
    }

    table.helpPageTable{
        border: 1px solid #000;
        padding: 5px;
        
        th, td{
            min-width: max-content;
            border: 1px solid #000;
            text-align: center;
            padding: 5px;
        }
    }

}

.exampleSection{
    border: 1px solid var(--PrimaryColor);
    padding: 1rem;
    margin-top: 50px;
    font-size: 0.9rem;
    position: relative;
    border-radius: 10px;
    background-color: #fff;

    .title{
        position: absolute;
        border-radius: 10px;
        top: -1.4rem;
        font-size: 1rem;
        color: #fff;
        background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
        padding: 5px;
        right: -10px;

    }
}