
.Sidebar{
    height: 100%;
    font-family: 'Ubuntu', sans-serif;
  }
  
  .SidebarList{
    height: auto;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  
  .SidebarList .ListItem{
    height: 64px;
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: 6px solid transparent;
  }
  
  .ListItem:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .ListItem.active{
    border-left-color: #c11c43 !important;
  }
  
  .ListItem #icon{
    display: grid;
    place-items: center;
    color: #c11c43;
    padding: 10px;
  }
  
  .ListItem #title, .ListItem #title a,.ListItem #title a:visited{
    flex: 1 auto;
    font-size: 16px;
    text-align: left;
    padding-left: 20px;
    text-decoration: none !important;
    color: #000;
  }
  
  .SidebarList a {
    text-decoration: none !important;
  }
  
  #subTrigger{
    place-items: center;
    display: grid;
    color: #c11c43;
    transform: rotate(-180deg);
    margin-right: 10px;
  }
  
  .ListItem.active #subTrigger{
    transform: rotate(0deg);
  }
  
  .subMenuList{
    height: auto;
    width: 100%;
    padding: 20px;
  }
  
  .subMenuList .subListItem{
    height: 55px;
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: left;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 30px;
  }
  
  .subListItem:hover{
    cursor: pointer;
    border-left-color: #c11c43;
  }
  
  .subListItem.active{
    border-left-color: #c11c43 !important;
  }
  
  .componentsContainer{
    height: 100%;
  }

  .componentsPage{
    position: relative;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    padding: 50px 20px 20px 20px;
    min-height: 28vw;
  }