@import "../views/variables.scss";

.forgotpasswordcontainer.padding-bottom-3x.mb-2.mt-5 {
    width: 100%;
    height: 50rem;
    flex-direction: row;
    flex-wrap: wrap;
    background-image: url(../../assets/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    display: flex;
    font-stretch: normal;
    position: relative;
    font-family: 'Ubuntu-Medium';
    overflow: hidden;
    margin: 0 auto;
    justify-content: center;
    h2 {
        font-family: 'Ubuntu-Bold';
        background: linear-gradient(117deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        font-weight: 900;
        line-height: 72px;
        letter-spacing: -0.5px;
    }
    h3 {
        font-family: Ubuntu-Regular;
        font-size: 12px;
        color: #7b7b7b;
        line-height: 1.71;
        letter-spacing: 0.4px;
        text-align: left;
    }
    h4 {
        display: block;
        font-family: 'Ubuntu-Regular';
        color: rgba(0, 0, 0, 0.6);
        font-size: 24px;
        padding-bottom: 60px;
        line-height: 0;
        letter-spacing: 0.18px;
    }
/* Resul -->> I changed form-control's place. Because when ot is
out of the  forgotpasswordcontainer it change the css of Register.js page. (05/19/22)*/
    .form-control {
        font-family: 'Ubuntu-Regular';
        border: none;
        border-bottom: 0.5px solid var(--black);
        outline: none;
        background: transparent;
        border-radius: 0%;
    
        &:focus {
            border-color: var(--black);
            box-shadow: none;
        }
        .btn-check:focus + .btn, .btn:focus{
            box-shadow: white !important;
        }
        .btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus{
            box-shadow: white !important;
            border: none;
            background: transparent;
            outline: none;
        }
    }
}

.ForgotContainer {
    display: flex;
    background-color: var(--white);
    margin: 0 auto;
    width: 446px;
    height: 500px;
    border-radius: 8px;
    justify-content: center;
}

.btn-primary {
    border-radius: 4px;
    background-image: linear-gradient(117deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor) 100%);
    font-family: 'Ubuntu-Medium';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    color: var(--white);
    padding: 8px 16px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    border-color: transparent;
    &:hover {
        opacity: 0.9;
        color: var(--white);
        border-color: transparent;
    }
}
.card-body {
    width: 400px;
}
.card-footer {
    background-color: var(--white);
    border-top: 10px;
}
.card.mt-4 {
    width: 100%;
    border: none;
    align-items: center;
}
.form-group {
     margin: 0px 40px 0px 0px
}

