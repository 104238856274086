@import "./variables.scss";

#coverageParams-form{
  .label-form{
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.4px;
    text-align: left !important;
    color: #7b7b7b;
    text-align: start;
    padding: 0px;
    margin: 0px !important;
  }
}

.form {
  &__NewQuote__MainTitle {
    background-image: linear-gradient(
      97deg,
      var(--TitlePrimaryColor) 0%,
      var(--TitleSecondaryColor) 100%
    );
    font-family: Ubuntu-Regular;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.18px;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__container {
  }
  &__firstpart {
  }
  &__secondpart_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__Middle_SiteOne {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    &_left {
      background-image: linear-gradient(
        97deg,
        var(--TitlePrimaryColor) 0%,
        var(--TitleSecondaryColor) 100%
      );
      font-family: Ubuntu-Medium;
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 1.25px;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &_right {
      min-width: 200px;
      border-radius: 40px;
      color: var(--FooterSecondaryColor);
      font-family: Ubuntu-Regular;
      font-size: 12px;
      letter-spacing: 0.4px;
      text-align: right;
      justify-content: flex-end;
      padding: 0.3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &_right_Address {
      cursor: pointer;
      border: 1px solid;
      padding: 3px 3px 3px 3px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &_right_Address.active {
      cursor: pointer;
      border-right: 1px solid var(--PrimaryColor);
      background-color: var(--orange);
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      color: var(--red);
      border-right: 1px solid var(--red);
      padding: 3px 3px 3px 3px;
    }
    &_right_Coordinate{
      cursor: pointer;
      border: 1px solid;
      padding: 3px 3px 3px 3px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &_right_Coordinate.active {
      cursor: pointer;
     /*  border-right: 1px solid var(--PrimaryColor); */
      background-color: var(--orange);
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      color: var(--red);
      padding: 3px 3px 3px 3px;
    }
  }
  &_searchIcon{
    width: 50px;
    height: 39px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    border-radius: 4px;
    background-image: linear-gradient(128deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor)100%);
    color: white;
  }
  &__viewMap{
    width: 71px;
    height: 19px;
    background-image: linear-gradient(128deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor)100%);
    font-family: Ubuntu-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__Bottom_SiteOne {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    &_left {
      background-image: linear-gradient(
        97deg,
        var(--TitlePrimaryColor) 0%,
        var(--TitleSecondaryColor) 100%
      );
      font-family: Ubuntu-Medium;
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 1.25px;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_right {
      border-radius: 40px;
      color: var(--FooterSecondaryColor);
      font-family: Ubuntu-Regular;
      font-size: 12px;
      letter-spacing: 0.4px;
      text-align: center;
      padding: 0.3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      &_two_Adress{
        cursor: pointer;
        border: 1px solid;
        padding: 3px 3px 3px 3px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &_two_Adress.active {
        cursor: pointer;
        border-right: 1px solid var(--PrimaryColor);
        background-color: var(--orange);
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        color: var(--red);
        border-right: 1px solid var(--red);
        padding: 3px 3px 3px 3px;
      }
      
      &_two_Coordinates{
        cursor: pointer;
        border: 1px solid;
        padding: 3px 3px 3px 3px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &_two_Coordinates.active {
        cursor: pointer;
        border-right: 1px solid var(--PrimaryColor);
        background-color: var(--orange);
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        color: var(--red);
        padding: 3px 3px 3px 3px;
      }
    }
  } 
}

/* ## To elimina the default box shadow characters and tive new one ## */
.form-check-input:checked {
  background-color: var(--BtnPrimaryColor) !important;
  border-color: var(--BtnPrimaryColor) !important;
}
/* ## To elimina the default box shadow characters and tive new one ## */
.form-check-input:focus {
  border-color: var(--BtnPrimaryColor) !important;
  box-shadow: none !important;
}

.siteFormContainer{
  //background-image: linear-gradient(to top, rgba(234, 234, 234, 0.63) 0%, rgba(245, 245, 245, 0.3) 50%, rgba(234, 234, 234, 0.47) 97%);
  background-color: #f9f9f9;
}

/* V2 */

.quoteSiteContainer{

  .searchButton{
    background-image: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
    color: #fff !important;
  }

  .heading{
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    font-family: 'Ubuntu-Medium';
    font-size: 1rem;

    .left, .middle, .right{
      height: 25px;
    }

    .left{
      text-align: left;
      background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      
      .siteHeadingTitle{
        display: flex;
        flex-direction: row;

        .iconBox{
          margin-right: 10px;

          &:hover{
            cursor: pointer;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }

    .middle{
      text-align: center;
    }

    .right{
      text-align: right;
      font-family: Ubuntu-Regular;
      letter-spacing: 0.4px;
      display: flex;
      flex-flow: row-reverse;
      .siteHeadingAdd{
        display: flex;
        flex-direction: row;
        color: var(--FooterSecondaryColor) !important;

        .iconBox{
          margin-right: 2px;
        }
        .textBox{
          font-size: 0.8rem;
          align-self: self-end;
        }

        &:hover{
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          padding-right: 5px;
          padding-left: 5px;
        }
      }
    }
  }

  .content{
    background-color: rgba(0, 0, 0, 0.03);
    position: relative;

    .isLoading{
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      margin: 0 !important;
      padding: 0 !important;
      top: 0 !important;
      left: 0 !important;
      z-index: 999;
      display: flex;
    }

    .badge{
      font-family: 'Ubuntu-Regular';
      font-size: 12px;
      letter-spacing: 0.4px;
      font-weight: 500;
    }

    .resetForm{
      text-align: center;
      background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &:hover{
        cursor: pointer;
        color: var(--TitlePrimaryColor) !important;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .quoteSiteContainer{
    .heading{ 
      .left, .middle, .right{
        text-align: left !important;
      }

      .right{
        flex-flow: row !important;
      }
    }
  }
}

.asyncselect__loading-indicator {
  display: none !important;
  & > * {
    display: none !important;
  }
}