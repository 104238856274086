@import "../views/variables.scss";

//contenitore principale del body
.container-login{
  min-height: 100vh !important;
  background-image: url(../../assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  align-items: center;
  position:relative;
  font-family: 'Ubuntu-Medium';
  margin: 0;
  padding: 0;
  overflow: hidden;

  .left-heading{
    color: var(--white);
    font-family: 'Ubuntu-Medium';
    margin-left: 10em;

    h1 {
      font-family: 'Ubuntu-Light';
      font-size: 3.5rem;
      color: var(--white);
      letter-spacing: 1px;
    }
    h2 {
      font-size: 4em;
      color: var(--white);
      font-weight: 900;
      letter-spacing: 1px;
      word-wrap: break-word;
    }

    h3 {
      font-family: 'Ubuntu-Light';
      size: 1.5rem;
      letter-spacing: 0.3px;
      text-align: left;
    }

    h4 {
      font-family: 'Ubuntu-Light';
      font-size: 3rem;
      color: var(--white);
      letter-spacing: 1px;
    }
  }

  .login-form{
    border-radius: 8px;
    max-width: 30rem;
    padding: 1rem;
    margin: 0 auto;
    background-color: var(--white);
    height: max-content;

    .container{
      padding: 3rem;
      height: max-content;
      min-height: max-content;
    }

    h2{
      background: linear-gradient(117deg,#ed3a7f,#ff6033);
      background: linear-gradient(117deg,var(--BtnPrimaryColor) 0,var(--BtnSecondaryColor) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: -0.5px;
      font-family: 'Ubuntu-Bold';
    }

    input{
      border-radius: 0px !important;
      font-size: 16px;
      border: none;
      border-bottom: 0.5px solid var(--black);
      outline: none;
      background: transparent;
    }

    .toggle-password{
      position: absolute;
      right: 1rem;
      bottom: 0.5rem;
      z-index: 59;
    }

    .form-label{
      font-family: 'Ubuntu-Light';
      font-size: 12px;
      color: var(--FooterSecondaryColor);
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
    }

    button{
      width: 6rem !important;
      text-transform: uppercase;
      letter-spacing: 1.50px;
      text-align: center;
      font-size: 0.8rem;
      font-weight: bold;
      font-family: 'Ubuntu-Light';
    }

    .forgotPassword{
      font-size: 12px;
      color: var(--FooterSecondaryColor);
      letter-spacing: 0.4px;
      font-family: 'Ubuntu-Regular';
    }

    a{ 
      text-decoration: none;
      color: inherit;
    }
    
    .foot{
      font-size: 1.25rem;
      letter-spacing: 0.5px;
      font-family: 'Ubuntu-Light';
      background: linear-gradient(to bottom, var(--PrimaryLoginColor) 11%, var(--SecondaryLoginColor) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      h5{
        bottom: 0 !important;
        margin-bottom: 0px !important;
      }

      a{
        font-weight: bold;
      }
    }
  }

}

/* Responsive Classes */
@media screen and (max-width: 1024px){
  .left-heading{
    margin-left: 2rem !important;
    margin-top: 3rem !important;
  }
  .login-form{
    margin-top: 1rem !important;
    max-width: 30rem !important;
    margin-bottom: 5rem !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}

