@import "./variables.scss";

.PricebookCard{
    display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 8px;
      box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.09);
      border: solid 1px #e7e8eb;
      background-color: var(--white);
      padding: 2rem;
  
      .title{
        color: var(--FooterPrimaryColor);
        font-family: Ubuntu-Regular;
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.18px;
        text-align: center;
      }
  
      .icon{
        align-self: center;
        font-size: 3rem;
        background: -webkit-linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
      }
  
      .description{
        color: var(--FooterSecondaryColor);
        align-self: stretch;
        font-family: Ubuntu-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.25;
        text-align: center;
      }
  
  }

.uploadZone{
    padding: 3rem;
    background-color: #f3f3f3;
    border: 1px dashed #ebebeb;

    p{
        color: var(--FooterSecondaryColor);
        font-family: Ubuntu-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.25;
    }
}

#AddPricebookForm{
  h5.subTitle{
    font-family: Ubuntu-Regular;
    font-size: 1.1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.15px;
    text-align: left;
    color: var(--FooterPrimaryColor);
  }
}