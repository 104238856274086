@import "../views/variables.scss";

.Admin_Title{
  background-image: linear-gradient(97deg, var(--TitlePrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
  font-family: Ubuntu-Regular;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.AdminCard{
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #e7e8eb;
    background-color: var(--white);
    padding: 2rem;
    height: 100%;

    &:hover{
      box-shadow: inset 0 8px 13px 0 rgba(0, 0, 0, 0.09);
      cursor: pointer;
      transition: ease-in-out 0.5s;
    }

    .title{
      color: var(--FooterPrimaryColor);
      font-family: Ubuntu-Regular;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.18px;
      text-align: center;
    }

    .image{
      align-self: center;
      width: 80px;
      height: 80px;
    }

    .description{
      color: var(--FooterSecondaryColor);
      align-self: stretch;
      font-family: Ubuntu-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25;
      text-align: center;
    }

}

.Admin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  &__Addteam_members {
    width: 21.7rem;
    height: 27rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 75px 25px 0 0;
    padding: 50px 25px 50px 25;
    border-radius: 8px;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #e7e8eb;
    background-color: var(--white);
    &:hover{
      box-shadow: inset 0 8px 13px 0 rgba(0, 0, 0, 0.09);
      cursor: pointer;
      transition: ease-in-out 0.5s;
    }
    /* title */
    &_title {
      color: var(--FooterPrimaryColor);
      font-family: Ubuntu-Regular;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.18px;
      text-align: center;
      margin: 4rem 0 3rem 0;
    }
    &_image {
        margin: 0.5rem 0 2rem 0;
    }
    /* title of the "p" tag */
    span{
        color: var(--black);
        font-family: Ubuntu-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.25px;
        text-align: center;
    }
    /* tag "p" */
    &_text {
      color: var(--FooterSecondaryColor);
      align-self: stretch;
      font-family: Ubuntu-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25;
      text-align: center;
      margin: 0.4rem 0.5rem;
    }
  }
  &__Manageteam_members {
    width: 21.7rem;
    height: 27rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* gap: 58px; */
    margin: 75px 24px 0 25px;
    /* padding: 100px 53px 150px; */
    border-radius: 8px;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #e7e8eb;
    background-color: var(--white);
    &:hover{
      box-shadow: inset 0 8px 13px 0 rgba(0, 0, 0, 0.09);
      cursor: pointer;
      transition: ease-in-out 0.5s;
    }
    &_title {
      color: var(--FooterPrimaryColor);
      font-family: Ubuntu-Regular;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.18px;
      text-align: center;
      margin: 4rem 0 3rem 0;
    }
    &_image {
        margin: 0.5rem 0 2rem 0;
    }
    span{
        color: var(--black);
        font-family: Ubuntu-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.25px;
        text-align: center;
    }
    &_text {
      color: var(--FooterSecondaryColor);
      align-self: stretch;
      font-family: Ubuntu-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25;
      text-align: center;
      margin: 0.4rem 0.5rem;
    }
  }
  &__Company_Profile {
    width: 21.7rem;
    height: 27rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 75px 24px 0 25px;
    border-radius: 8px;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #e7e8eb;
    background-color: var(--white);
    text-decoration: none;
    &:hover{
      box-shadow: inset 0 8px 13px 0 rgba(0, 0, 0, 0.09);
      cursor: pointer;
      transition: ease-in-out 0.5s;
    }
    &_title {
      color: var(--FooterPrimaryColor);
      font-family: Ubuntu-Regular;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.18px;
      text-align: center;
      margin: 4rem 0 3rem 0;
    }
    &_image {
        margin: 0.5rem 0 2rem 0;
    }
    span{
        color: var(--black);
        font-family: Ubuntu-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.25px;
        text-align: center;
    }
    &_text {
      color: var(--FooterSecondaryColor);
      align-self: stretch;
      font-family: Ubuntu-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25;
      text-align: center;
      margin: 0.4rem 0.5rem;
    }
  }
}
/* ## To delete the underlines which come after I added Link attribute ## */
.Link__delete_underline{
  text-decoration: none;
}
