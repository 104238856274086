@import 'bootstrap/scss/bootstrap';

.App {
    text-align:left;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

/* FJ */
.fadedButton{
    background-image: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
    color: #fff !important;
    margin-left: 5px;
    margin-right: 5px;
    min-width: 5rem;
}

.fadedButton:hover, .fadedButton:active, .fadedButton:focus{
    background-image: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
    background-color: transparent;
    box-shadow: none;
}

.pinkText{
  background: -webkit-linear-gradient(95deg, #ed3a7f 0%, #ff6033 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
  font-family: 'Ubuntu-Light';
  letter-spacing: 0.5px;
  font-stretch: normal;
  font-style: normal;
}


  