@import "../views/variables";

.BreadCrumbs{
    display: flex;
    flex-direction: row;
    align-items: center;

    .firstIcon svg{
        color: var(--FooterSecondaryColor);
        font-size: 25px;
        margin-right: 10px;
    }
    .firstValue{
        font-size: 18px;
        font-weight: bold;
        background: -webkit-linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
         margin-right: 5px;
         text-transform: capitalize;
    }

    .secondIcon svg{
        color: var(--FooterSecondaryColor);
        font-size: 10px;
        margin-right: 5px;
    }

    .secondValue{
        font-size: 18px;
        font-weight: 300;
        color: var(--FooterPrimaryColor);
    }
}