@import '../views/variables.scss';


.fadedText{
    background: -webkit-linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
}

.portalConfigurationWrapper{
    .configItem{
        padding: 5px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }

    .labelBox{
        align-self: center;
        font-size: 1.3rem;
        font-weight: bold;
    }
}