@import "./variables";

.ChangePasswordView_container{
    max-width: 100% !important;
    height: 60vw;
    //background-image: url(../../assets/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    display: inline-flex;
    align-items: center;
    position:relative;
    font-family: 'Ubuntu-Medium';
    overflow: hidden;
    //background-color: aliceblue;
}

body .container{
    max-width: 100%;
}
