@import "../views/variables.scss";
.ChangePasswordForm {
  margin: 0 auto;
  /* error message feedback */
  &__control_feedback{
    font-size: 0.6rem;
    color: var(--red);
  }
  &_container {
    text-align: left;
    background-color: var(--white);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 2rem 2.5rem 2rem 2.5rem;
    /* Title */
    &_title {
      background-image: linear-gradient(
        101deg,
        var(--BtnPrimaryColor) 0%,
        var(--BtnSecondaryColor) 100%
      );
      font-family: Ubuntu-bold;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: -0.5px;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    /* Second Title */
    &_subtitle {
      font-family: Ubuntu-Regular;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.18px;
      color: var(--FooterPrimaryColor);
    }
     &_label {
      font-family: Ubuntu-Regular;
      font-size: 12px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.4px;
      text-align: left;
      color: var(--FooterSecondaryColor);
    }
    .col-form-label{
      padding-bottom: none;
    }
    .form-control:focus {
      border-color: none !important;
      box-shadow: none !important;
    }
    .form-control {
      border-radius: 0;
    }
  }
  /* another container for button */
  .Button_container{
    text-align: center;
      &_color {
    border-radius: 4px;
    background-image: linear-gradient(
      117deg,
      var(--BtnPrimaryColor) 0%,
      var(--BtnSecondaryColor) 100%
    );
    font-family: Ubuntu-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    color: var(--white) !important;
    padding: 8px 16px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 6rem;

    &:hover {
      opacity: 0.9;
      color: none;
    }
  }
  }
}
/* ## Close button ## */
.btn-close:focus {
  box-shadow: none !important;
}

/* ## When the password strong the color change the green ## */
.Password__Strong{
  color: green;
}
.Eye__Icon{
  color: rgba(0, 0, 0, 0.6);
  float: right;
  position: relative;
  top: -2rem;
  right: 0.5rem;
}
/* To cancel the shodow and color after clicking */
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus{
  border-color: none ;
  box-shadow: none !important;
}
/* to cancel the green shadow after the password is correct */
.was-validated .form-control:valid:focus, .form-control.is-valid:focus{
  border-color: none ;
  box-shadow: none !important;
}
/* To cancel the focus of the Button */
.btn-check:focus + .btn, .btn:focus{
  box-shadow: none !important;
}

/* to cancel the  tick symbol */
.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none !important;
}

