:root {
    --PrimaryColor: #c11c43;
    
    --BtnPrimaryColor:#ed3a7f ;
    --BtnSecondaryColor: #ff6033;

    --TitlePrimaryColor:#f05f96;
    --TitleSecondaryColor:#ff7f59 ;
    
    --FooterPrimaryColor:#00000099; /* rgba(0, 0, 0, 0.6) */
    --FooterSecondaryColor:#7b7b7b;
    --FooterSocialMedBgColor: #0000001f; /* rgba(0, 0, 0, 0.12) */

    --LightGrey: rgba(255, 62, 4, 0.04);
    --LighterGrey: #ededed; 

    --RegistrationPrimaryColor: #161616;
    
    --white: #ffffff;
    --black: black;
    --orange:#fee8e6; /* inside the NewQuote page */
    
    --red: red;
    --blue: #1e90ff;
    --outline: #ffffff;
    --PrimaryLoginColor:#1e6e89;
    --SecondaryLoginColor: #1c637b;

    --MediumEmphasis: rgba(0, 0, 0, 0.6);
    
}