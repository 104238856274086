@import  "../views/variables.scss";

$tabGrey: #7b7b7b;

.ol-breadcrumbs{
    text-align: left;
    vertical-align: middle;
}

.fadedText{
    background: -webkit-linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
}

.text-left{
    text-align: left !important;
}

.text-right{
    text-align: right !important;
}

.text-center{
    text-align: center !important;
}

.head_searchBar{
    border-left: none;
    border-color: rgba(33, 33, 33, 0.08);
}

.searchIcon{
    background-color: transparent !important;
    color: #7b7b7b;
    border-color: rgba(33, 33, 33, 0.08);
}

button.transparentButton{
    color: $tabGrey !important;
    background-color: transparent;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
}

button.transparentButton.detailsVariant{
    border: 1px solid rgba(33, 33, 33, 0.08);
}

button.transparentButton.detailsVariant:hover, button.transparentButton.detailsVariant:active, button.transparentButton.detailsVariant:focus{
    border: 1px solid rgba(33, 33, 33, 0.08);
}

button.transparentButton:hover, button.transparentButton:active, button.transparentButton:focus{
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.fadedButton{
    background-image: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
    color: #fff !important;
    margin-left: 5px;
    margin-right: 5px;
}

.fadedButton:hover, .fadedButton:active, .fadedButton:focus{
    background-image: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
    background-color: transparent;
    box-shadow: none;
}

.fadedBorderButton{
    background-color: transparent;
    color: #ff6033;
    border: 1px solid;
    margin-left: 5px;
    margin-right: 5px;
    border-image-slice: 2;
    border-image-source: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
    border-radius: 0.25rem !important;
}

.fadedBorderButton:hover, .fadedBorderButton:active, .fadedBorderButton:focus{
    border-image-slice: 2;
    border-image-source: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
    border-radius: 0.25rem !important;
}

.styleText{
    color: $tabGrey;
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding: 16px;
}
table.DataTables{
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
}


.headingRow{
    margin-bottom: 35px;       
}

.DataTables td{
  color: #000;
  font-family: 'Ubuntu', sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 16px 5px 16px 5px;
}

.DataTables th {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: left;
  color: $tabGrey;
  padding: 14px;
}

.DataTables tbody > tr > td{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

// table.table.DataTables tbody{
//     border-top: 1px solid rgba(0, 0, 0, 0.2);
// }

.filterButton{
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    margin: 5px;
}

.filterIcon{
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6);
    vertical-align: text-bottom;
}

.FilterDropdownBox{
    position: absolute;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    padding: 5px;
    background-color: #fff;
    z-index: 10;
    text-align: left;
}

.FilterDropdownBox ul{
    list-style: none;
    padding: 15px;
    margin: 0px;
}

.filterHead{
    color: $tabGrey;
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.filterOptions{
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding: 8px;
}

.paginationRow{
    padding-top: 50px;
}

.paginationTransparentButton{
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.filtersRow{
    padding-top: 25px;
    padding-bottom: 25px;
}

.tableHeadHR{
    width: 100%;
    position: absolute;
    margin: 0;
    left: 0;
    box-shadow: 0px 2px 4px 4px rgb(204 204 204);
}

.paginationRow select {
    border: none;
    font-weight: normal;
    color: $tabGrey;
}

.paginationRow{
    color: $tabGrey;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding: 16px;
}

.paginationRow button{
    color: $tabGrey;
    font-family: 'Ubuntu', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.paginationRow p{
    margin: 0px;
    vertical-align: bottom;
    padding: 5px;
}

.paginationTransparentButton.active{
    color: #c11c43;
    transform: scale(1.3);
    font-weight: bold;
}

.flipIcon {
    transform: rotateX(180deg);
}

.centeredcontent{
    justify-content: center;
    text-align: center;
}

.tabRedDot span{
    color: #ff0000 !important;
    font-size: 10px;
}

.tabGreenDot span{
    color: #00ff00 !important;
    font-size: 10px;
}


input[type="search"].head_searchBar::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-image: url(../../assets/icons8-eliminare-16.svg);
}

.ol-details-heading{
    font-weight: bold;
    background: -webkit-linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
    margin-right: 10px;
}

.styledHR{
    background-image: linear-gradient(to right, #ed3a7f 0%, #ff6033 100%);
    height: 2px;
}

.ol-details-title{
    color: $tabGrey;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    padding-left: 20px;
    bottom: 0px !important;
}

table.DataTables.ol-details-variant td{
    font-size: 14px;
    color: $tabGrey;
    text-align: left;
    padding-left: 10px;
}

table.DataTables.ol-details-variant td:nth-child(2){
    font-weight: bold;
}

table.DataTables.ol-details-variant tr:last-child{
    td{
        border: none !important;
    }
}

.ol-details-addressLine{
    font-size: 14px;
    color: $tabGrey;
    text-align: left;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 15px;
}

.ol-details-footer{
    background-color: var(--LightGrey);
    width: 100%;
    margin: 0px 0px 16px 0px;
    left: 0;
    padding: 15px;
    bottom: 15px;
}

.manageCompany .DataTables th:last-child{
    width: 5%;
}

.manageCompany .DataTables th:nth-last-child(2){
    text-align: center;
}

.manageCompany .tabIcon, .tabIcon a{
    color: var(--PrimaryColor) !important;
    //text-align: center;
}

.item-right {
    justify-content: right;
    align-content: right;
    align-items: right;
    text-align: right;
}

.QuoteSubDiv.QuoteSubDivAlt {
    td{
        background-color: #f9f9f9;
        border-bottom: 2p solid green;
    }
}

.QuoteSubDiv {
    tr{
        border: none;
    }

    td{
        background-color: #f3f3f3;
        font-size: 12px;
        border: none;
        font-size: 11px;
        color: $tabGrey;
        text-align: left;
        border-bottom: 2p solid red;
    }
    ul{
        list-style: none;
        columns: 2;
    }
    li{
        padding-top: 5px;
        letter-spacing: 0.4px;
        margin-bottom: 5px;
    }
    div.pricebook{
        align-self: center;
    }
    .pricebook select{
        min-width: 80%;
        font-size: 13px;
    }

    button{
        font-size: 12px;
        align-self: center;
    }
}

th#tableTH:hover{
    cursor: pointer;
}

.sortedTabAsc::after{
    content: ' ▲';
}

.sortedTabDesc::after{
    content: ' ▼'
}

.teammembers{
    table tr th:last-child{
        width: 60px;
    }
}

.quoteList{
    table tr th:nth-last-child(-n+3){
        width: 50px;
    }
}

.clientList{
    table tr th:last-child{
        width: 60px;
    }
}

.unselectedButton{
    width: 35px;
    height: 35px;
    border-radius: 100%;
    border: 1px solid red;
    background-color: transparent;
    color: red;
}

.unselectedButton:hover{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: red;
    color: #fff;
}

.selectedButton{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
    &.confirm{
        background-color: green;
    }
    &.feasibility{
        background-color: #ff6033;
    }
}

input.head_searchBar.form-control:focus{
    border-color: rgba(33, 33, 33, 0.08) !important;
    box-shadow: none !important;
}

@media only screen and (max-width: 600px){
    .styledHR, .tableHeadHR{
      display: none;
    }
  }

  .tabIcon, .tabIcon a{
    color: var(--PrimaryColor) !important;
    //text-align: center;
}