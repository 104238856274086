
.filterButton{
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    border: 1px solid #e7e8eb;
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    margin: 5px;
}

.filterIcon{
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6);
    vertical-align: text-bottom;
}
