@import "./variables";

/* ##### Header Container ###### */
.header {
  overflow: visible;
  width: 100%;
  max-height: 4rem;
  background-color: var(--PrimaryColor);
  padding: 20px 10px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2rem;
  margin-bottom: 2rem;

  &.NoAuthHeader{
    margin: 0px !important;
    z-index: 9999;
  }

  /* ####### col-dx ########## */
  &__col_sx {
    &_logo {
      img {
        width: 156px;
        height: 127px;
        position: absolute;
        top: -6px;
        left: -7px;
        z-index: 3;
      }
    }
    /* #### Left side Brandname #### */
    span {
      .header__col_sx_brandname {
        height: 2.8rem;
        flex-grow: 0;
        margin: -13px 5px 6px 154px;
        background-image: linear-gradient(
          to bottom,
          var(--white),
          var(--white)
        );
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: var(--white);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .brandname_text-style {
        font-weight: 300;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Ubuntu-Medium;
      }
    }
  }
  /* ####### col-dx ########## */
  .col__dx {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 3rem;
    /* ## consist of search input and search icin ## */
    &_search_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      /* Input part */
      input {
        &.search {
          width: 250px;
          height: 30px;
          align-self: stretch;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin: 0 0 0 0;
          padding: 5px 9px;
          border-radius: 4px;
          border: solid 1px var(--outline);
          background-color: var(--white);
          outline: none;
        }
      }
      /* search icon */
      img {
        width: 15px;
        height: 15px;
        flex-grow: 0;
        border: none;
        position: absolute;
        right: 10px;
        display: inline-block;
      }
    }
    /* notification icon */
    &_notification_icon {
      img {
        width: 24px;
        height: 24px;
        margin: 8px 29.5px 6px 49.5px;
        cursor: pointer;
      }
      &_onClick{
        position: absolute;
        z-index: 10;
        background-color: var(--white);
        border-radius: 5px;
        box-shadow: 0 8px 13px 0 rgb(0 0 0 / 9%);
        margin-top: 8rem;
        right: 11rem;
        display: flex;
        flex-direction: column;
        width: 15%;
        height: 100%;
    }
    }
    /* Pipe */
    &_pipe {
      border-left: 2px solid var(--white);
      height: 2rem;
      opacity: 0.6;
    }
    /* Fiber telecon written part container - icon and Fiber telecom- */
    &_brandname_container {
      /*       display: flex;
      flex-direction: row; */
      flex-grow: 0;
      margin: 0 0 1.5rem 1rem;
      font-family: Ubuntu-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: right;
      color: var(--white);
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      &_first {
        display: flex;
        flex-direction: row;
        align-items: center;
        /* ## right side Fiber telecom written part ## */
        &_icon {
          img {
            width: 1.1rem;
            height: 1.1rem;
            margin-left: 1rem;
          }
        }
        /* I added this class later yo put a backgropund-color to the opening bar under fiber telecom */
      }
      &_isOpen_bg {
        position: absolute;
        z-index: 10;
        background-color: var(--white);
        border-radius: 5px;
        box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.09);
        margin-top: 1rem;
        right: 30px;
      }
      /* ### BrandName Onclick. It opens it someone clicks it### */
      &_Onclick {
        display: flex;
        flex-direction: column;
        font-family: Ubuntu-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.25px;
        text-align: left;
        color: var(--FooterPrimaryColor);
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin: 0 1rem 0 0;
        &_hr{
          margin: 0.5px 0;
          color: #7b7b7b;
          border: 0;
          opacity: 0.25;
        }
        &_parag1 {
          height: 3rem;
          padding: 0.5rem 0 0 0;
          text-decoration: none;
          color:inherit ;
        }
        &_parag2 {
          height: 3rem;
          padding: 0.5rem 0 0 0;
          text-decoration: none;
          color:inherit ;
        }
        &_parag3 {
          margin: 0;
          height: 3rem;
          padding: 0 0 0 0;
          text-decoration: none;
          color:inherit ;
        }
        &_parag4 {
          /* height: 3rem; */
          padding: 0.7rem 0 0 0;
        }
        p{
          padding: 0.7rem 0 0 1rem;
        }
      }
    }
  }
}

/* ## To give a hover to fibertelecom sub-bar ## */
.bg__myProfile:hover{
  background-color: rgba(255, 62, 04, 0.04);
}
.bg__admin:hover{
  background-color: rgba(255, 62, 04, 0.04);
}
.bg__logout:hover{
  background-color: rgba(255, 62, 04, 0.04);
}
.bg__ChangePassword:hover{
  background-color: rgba(255, 62, 04, 0.04);
}

/* ## This part I added to eliminate the 'x' character on the right side of the input.type=search ## */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.breadCrumbs{
  margin-left: 240px;
  padding: 10px;
  margin-bottom: 2rem;
}

.language_switcher{
    padding: 5px;
    background-color: #AAA4;
    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: right;
    color: var(--white);
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;

    select{
      background-color: transparent;
      border: none !important;
      color: #fff;

      option{
        background-color: #AAA4;
        border: 3px solid #fff;
        color: #333;
      }
    }
}

.languageselector{
  margin: 0 0 1.5rem 1rem;
  font-family: Ubuntu-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: var(--white);
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;

  .trigger{
    display: flex;
    font-size: 16px;
    align-items: center;
    
  }

  .subdiv{
    width: max-content;
    margin-top: 5px;
    padding: 10px;
    padding-bottom: 0px;
    color: #333;
    position: absolute;
    right: -10px;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0 8px 13px 0 rgb(0 0 0 / 9%);
    z-index: 999;

    ul{
      padding: 0;
      list-style-type: none;

      li{
        gap: 10px;
        padding: 5px;
        font-size: 14px;
        letter-spacing: 0.25px;
      }
      li:hover{
        background-color: #ededed;
      }
      li:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
}

.burgermenu{
  display: none;
}

/* Responsive Classes */
@media screen and (max-width: 1024px){
  .breadCrumbs{
    margin: 3rem auto 1rem auto;
  }
  .languageselector{
    display: none;
  }

  .NoAuthHeader {
    .languageselector{
      display: block !important;
    }
  }
  .col__dx_brandname_container{
    display: none;
  }
  .burgermenu{
    display: block;
    left: 0;
    color: #fff;
  }
  .navSidebar{
    display: none;
  }
}

@media screen and (max-width: 500px){
  .header__col_sx span .header__col_sx_brandname{
    display: none !important;
  }
}


/*  hr{
          border: 1;
          background-color: #ccc;
          color: #ccc;
          height: 1px;
        } */
/* ###### Responsive -- Can be eliminated if it is not necessary ########## */

/* @media (max-width: 768px){
  .header{
      overflow: visible;
      display: flex;
      flex-direction: column;
      .col-dx{
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          left: 40px;
          top: 92px;
          background-color: var(--PrimaryColor);
          z-index: 1;
          width: 100%;
          .search-container{
              margin: 0 0 0 1rem;
              input{
                  &.search{
                    
                  }
              }
          }
          .notification{
              display: none;

          }
          .pipe{
              display: none;
          }
          .write-notification{
            display: flex;
            font-family: Ubuntu;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: 0.25px;
            text-align: right;
            color: var(--white);
          }
          .brand-fibtel{

          }
          .Chevron-icon{

          }
          img{

          }
          .right-brand-img{

          }
      }
      .hamburger{
        img{
          margin-right: 1.5rem;
          padding: 1rem;
          background-color: var(--header-color);
          display: flex;
        }
      }
    }
} */
