@import "../views/variables.scss";

.Modal__title{
    align-items: center;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(95deg, var(--BtnPrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
    font-family: Ubuntu-Bold;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top:4rem;
    margin-bottom:3rem;
}
.Modal__body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Ubuntu-Regular;
    &_content{
        max-width: 40%;
        display: flex;
        text-align: center;
    };
}
.Modal__header{
    border-bottom: none !important;
    box-shadow:  0 3px 7px 0 rgba(0, 0, 0, 0.12);

    &.clientModal{
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.15px;
        text-align: left;
    }
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 400px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 20px 0 0 20px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
    padding: 15px 15px 80px;
}
.come-from-modal.right.fade .modal-dialog {
    right: 1px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
    right: 0;
}

.hr-faded-modal{
    opacity: 1 !important;
    height: 1px !important;
    width: 100%;
    background-image: linear-gradient(to right, #ed3a7f 0%, #ff6033 100%);
    margin-top: 0px;
}

.NewQuoteConfirmModal{
    .fadedButton{
        background-image: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
        color: #fff !important;
        margin-left: 5px;
        margin-right: 5px;
        border-image-slice: 2;
        border-image-source: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
    }
    
    .fadedButton:hover, .fadedButton:active, .fadedButton:focus{
        background-image: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
        background-color: transparent;
        box-shadow: none;
        border-image-slice: 2;
        border-image-source: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
    }
    
    .modal-header{
        border-bottom: none !important;
        box-shadow:  0 3px 7px 0 rgba(0, 0, 0, 0.12);
        font-size: 20px;
    }

    .modal-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        button{
            width: 200px;
        }
    }
    p{
        text-align: justify;
    }
}

.forgotPasswordModal{
    padding: 1.5rem;
    
    p{
        mix-blend-mode: multiply;
        font-family: 'Ubuntu-Light';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.4px;
        text-align: left;
        color: var(--FooterSecondaryColor);
    }

    h4 {
        flex-grow: 0;
        mix-blend-mode: multiply;
        font-family: 'Ubuntu';
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.18px;
        text-align: left;
        color: var(--FooterPrimaryColor);
    }

    h2{
        font-family: 'Ubuntu-Bold';
        background: linear-gradient(117deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: left;
        font-size: 2rem;
        font-weight: 900;
        letter-spacing: -0.5px;
    }

    input{
        display: block;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0px !important;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        border-bottom: 1px solid #000;
    }
    

    .form-label{
        mix-blend-mode: multiply;
        font-family: 'Ubuntu-Light';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.4px;
        text-align: left;
        color: var(--FooterSecondaryColor);
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }

    .modal-header{
        border-bottom: none !important;
    }
}


.pricebookMultiDisable{
    position: relative;
    
    input[type="checkbox"]:checked {
        accent-color: #ff6033;
        color: #fff !important;
    }
    .overlay{
        position: absolute;
        z-index: 998;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        margin: 0;
    }
    
    .fadedButton{
        background-image: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
        color: #fff !important;
        margin-left: 5px;
        margin-right: 5px;
        border-image-slice: 2;
        border-image-source: linear-gradient(111deg, #ed3a7f 0%, #ff6033 100%);
        width: 100%;

        border-radius: 5px;
                border: none;
                padding: 5px;
                font-size: 0.9rem;
                font-family: "Ubuntu", sans-serif !important;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.4px;
    }
    
    .fadedButton:hover, .fadedButton:active, .fadedButton:focus{
        background-image: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
        background-color: transparent;
        box-shadow: none;
        border-image-slice: 2;
        border-image-source: linear-gradient(111deg, #ff6033 0%, #ed3a7f 100%);
    }

    .fadedBorderButton{
        width: 100%;
    }
    .confirmationDiv{
        position: absolute;
        z-index: 999;
        background-color: #fff;
        padding: 1rem;
        top: 40%;
        width: 40vw;
        left: 30vw;
        background-color: white;
        border-radius:10px;
        //border: 1px solid rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding-box;
        background-clip: padding-box;

        .title{
            font-size: 1.3rem;
            font-family: "Ubuntu", sans-serif !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.4px;
        }
    }

    .modal-header{
        border-bottom: none !important;
        box-shadow:  0 3px 7px 0 rgba(0, 0, 0, 0.12);
        font-size: 20px;
    }

    .modal-body{
        padding: 2rem;

        table{
            width: 100%;
            table-layout: fixed;
            word-wrap: break-word;
    
            th{
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 0.4px;
                text-align: left;
                color: #7b7b7b;
                padding-top: 7px;
                padding-bottom: 7px;
                padding-left: 5px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
    
            td{
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                color: #000;
                font-family: "Ubuntu", sans-serif !important;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.4px;
                padding: 5px;
            }
        }
        
        .tableContainer{
            overflow-x: hidden;
            overflow-y: scroll;
            max-height: 35vw;
        }
    }


    .selectedBox{
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 10px;

        .title{
            position: absolute;
            top: -1.5rem;
            left: 1rem;
            background-color: #fff !important;
            padding: 5px;
            font-size: 1.3rem;
            font-family: "Ubuntu", sans-serif !important;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.4px;
        }

        .body{
            margin: 1rem; 
        }

        .bottom{
            position: absolute;
            text-align: right;
            bottom: -1rem;
            right: 1rem;

            button{
                border-radius: 5px;
                border: none;
                padding: 5px;
                font-size: 0.9rem;
                font-family: "Ubuntu", sans-serif !important;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.4px;
            }
        }
    }

    .selectedItems{
        padding: 0px 10px 0px 10px;
        border: 1px solid #ff6033;
        border-radius: 25px;
        margin: 5px;
        font-family: "Ubuntu", sans-serif !important;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.4px;
        width: max-content;
        display: inline-block;
    }
}