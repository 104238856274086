@import '../views/variables.scss';

// Contenitore di tutti i componenti
.MainContainer {
   width: 100%;
   height: 100%;
   align-items: center;
   overflow: hidden;

   
   .UnderLogin {
      //// To remove when video is ready
      display: none;
      
      z-index: 9999;
      //ToRemove later
      margin: 0 auto;
      color: #7b7b7b;
      margin-top: -10rem;
      padding: 20px;
      width: 80vw;
      height: 40rem;
      text-align: center;
      background-color: #ced4dad9;
   }

   .MiddleComponent{
      text-align: center;
      margin: 5rem auto;
      padding: 20px;
      height: calc(100% + 5rem);

      .title{
         letter-spacing: 0.5px;
         font-family: 'Ubuntu-Light';
         background: linear-gradient(to bottom, var(--PrimaryLoginColor) 11%, var(--SecondaryLoginColor) 100%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }

      .subTitle{
         font-size: 20px;
         letter-spacing: 0.5px;
         font-family: 'Ubuntu-Light';
         text-transform: uppercase;
         color: var(--FooterSecondaryColor);
         font-weight: bold;
      }

      p{
         font-size: 18px;
         color: var(--FooterSecondaryColor);
         text-align: center;
         letter-spacing: 0.5px;
         font-family: 'Ubuntu-Light';
      }

      h2.title{
         font-size: 3.5rem;
      }

      .imageIcon{
         background-image: linear-gradient(173deg, var(--PrimaryLoginColor) -28%, #448ba5 40%, #589ab5 65%, #589ab5 132%);
         width: fit-content;
         padding: 30px;
         margin: 1rem auto;
         border-radius: 50%;
         color: #fff;
      }
   }

   //contenitore della registration page
   .RegContainer {
      width: 100%;
      min-height: 60vh;
      background-image: url(../../assets/BackgroundRegistration.png);
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      padding-bottom: 3rem;

      .regCont {
         width: 60%;
         background: var(--white);
         z-index: 1;
         border: 1px solid #BFBFBF;
         box-shadow: 0 0 25px #888888;
         margin: 0 auto;
         position: relative;
         margin-bottom: 150px;
      }

   }

   @media screen and (max-width: 1080px){
      .regCont{
         margin: 0 auto;
         width: 95% !important;
      }
   }
}


//Sass del carosello
.CarouselContainer {
   align-items: center;
   background: var(--white);
   display: flex;
   height: 13vh;
   justify-content: center;
   padding: 100px 0px 150px 0px;

}


@mixin white-gradient {
   background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

$animationSpeed: 15s;
$animation-delay: 0s;

// Animazione degli slider
@keyframes scroll {
   from {
      transform: translateX(-1800px);
   }

   to {
      transform: translateX(1100px);
   }
}


// Stile dello slider e dei content
.slider {
   background: white;
   height: 80px;
   margin: auto;
   overflow: hidden;
   position: relative;
   width: 1280px;

   &::before,
   &::after {
      @include white-gradient;
      content: "";
      height: 80px;
      position: absolute;
      width: 100px;
      z-index: 2;
   }

   &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
   }

   &::before {
      left: 0;
      top: 0;
   }

   .slide-track {
      animation: scroll $animationSpeed linear infinite;
      animation-play-state: running;
      display: flex;
      width: calc(100% * 14);
   }

   .slide {
      display:flex;
      height: 100px;
      width: 300px;
   }
   //i brand
   .Fastweb {
   width: 230px;
   height: 50px;
   margin: 28px 0px 9px -10px;
  
  
}
.Linkem {
   width: 168px;
   height: 60px;
   
 }

 .Irideos {
    width: 160px;
    margin: 25px 0px 0px 40px
   }
   
 .tim {
    width: 130px;
    margin: 27px 0px 0px 30px;
 }
 .Mix  {
    margin: 18px 20px 0px 0px;

 }

 .OpenFiber {
margin: 22px 0px 0px -30px;
 }
 }