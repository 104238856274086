@import '../views/variables.scss';

.ResponsiveMenu {
  a {
    text-decoration: none;
  }

  .menuItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: #c11c43;
    margin-bottom: 1rem;
    padding: 10px;
    border: 1px solid #c11c43;
    background-color: #f8f8f8;

    .icon {
      flex-basis: 15%;
    }

    .value {
      flex-basis: 85%;
    }

    &.active{
        background-color: #c11c43;
        color: #fff;

        img{
            border: 1px solid #fff;
        }
    }
  }
}
