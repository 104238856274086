@import "./variables.scss";


.MyProfile_title{
    background-image: linear-gradient(95deg, var(--BtnPrimaryColor) 0%, var(--TitleSecondaryColor) 100%);
    font-family: Ubuntu-Light;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 3rem 2rem;
}

.MyProfile_Button_Container {
  display: flex;
  justify-content: center;
  cursor: auto;
  &_button {
    display: flex !important;
    border-radius: 4px;
    background-image: linear-gradient(
      117deg,
      var(--BtnPrimaryColor) 0%,
      var(--BtnSecondaryColor) 100%
    );
    font-family: Ubuntu-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    color: var(--white) !important;
    padding: 8px 16px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    &:hover {
      opacity: 0.9;
      color: var(--white);
    }
  }
}

/* the symbol of star */
.star_symbol {
  color: var(--red);
}

/* ## This class has been given later to align AsyncSelect of role ## */
.MyProfile__AsyncSelect_role{
  margin: 32px 0px 0px 0px;
}

.MyProfile_labels {
  color: var(--RegistrationPrimaryColor);
  font-family: Ubuntu-Regular;
  font-weight: 500;
}
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: none;
}
/* To change the color of the input boxes. red or green background comes as default color. */
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: rgb(168, 168, 168);
  /* background-image: none; */
}
/* Emails give error if they are wrong.This code takes the error part on the right. */
.Form__control_feedback {
  text-align: left;
}

/* to delete the '!' sign at the and of the each input column. */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
/* To delete the '!' sign at the end of the options column. */
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  background-image: none;
}
/* To delete the sign from the option after it is done correctly */
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  background-image: none;
}
/* Box-shadow change */
.form-control:focus {
  border-color: var(--BtnPrimaryColor);
  box-shadow: 0 0 0 3px rgba(255, 211, 198, 0.38);
}
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}
/* Select options box-shodow and borer color change */
.form-select:focus {
    border-color: var(--BtnSecondaryColor);
    box-shadow: 0 0 0 3px rgba(255, 211, 198, 0.38);
  }
  /* Select */
  .was-validated .form-select:valid:focus,
  .form-select.is-valid:focus {
    border-color: var(--BtnSecondaryColor);
    box-shadow: 0 0 0 3px rgba(255, 211, 198, 0.38);
  }
  /* Select */
  .was-validated .form-select:valid,
  .form-select.is-valid {
    border-color: rgb(168, 168, 168);
  }


