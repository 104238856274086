@import "../views/variables.scss";

.ForgotPasswordLandingContainer{
  min-height: calc(100vh - 64px);
  background-color: var(--LighterGrey);
  text-align: center;

  .content{
    margin-top: 35vh;

    h2{
      font-family: "Ubuntu-Bold";
      background: linear-gradient(117deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 2rem;
      font-weight: 900;
      letter-spacing: -0.5px;
    }

    h4{
      flex-grow: 0;
      mix-blend-mode: multiply;
      font-family: "Ubuntu";
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.18px;
      color: var(--FooterPrimaryColor);
    }
  }
}