@import "./variables.scss";

.dashboardPage{
    .title{
        font-size: 2.2rem;
        letter-spacing: 0.25px;
        font-weight: 500;
    }

    .statsCardContainer{
        padding: 1rem;
        display: flex;
        border-radius: 5px;
        color: #fff;

        &.fiberTelecom{
            background-color: var(--PrimaryColor);
        }

        &.outline{
            background-color: #FFFFFF00 !important;
            color: var(--PrimaryColor) !important;
            border: 1px solid var(--PrimaryColor);
        }
    
        .content{
            flex-basis: 80%;
    
            h5{
                font-size: 0.9rem;
                text-transform: uppercase;
                letter-spacing: 0.25px;
                font-family: 'Ubuntu-Bold';
            }
        }
    
        .icon{
            span{
                font-size: 4rem;
                color: inherit;
            }
        }
    }


    .dashboardCard{
        width: 100%;
        font-family: 'Ubuntu';
        color: var(--FooterPrimaryColor);
        background-color: #fff;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        border-radius: 8px;
        border: solid 1px #e7e8eb;
        padding: 1rem;
        

        .headerPart{
            display: flex;
            flex-direction: row;
            font-weight: 500;

            h3{
                font-size: 1.3rem;
                margin: 0;
                padding: 0;
            }
            
            span{
                color: var(--PrimaryColor);
                font-size: 1.5rem;
                margin-right: 0.3rem;
            }
            
        }

        .contentPart{
            color: #000;

            h3{
                font-weight: bold;
                font-size: 2rem;
            }
        }
    }
}