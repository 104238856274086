@import "../views/variables.scss";

@keyframes drawCircle {
  50% {
      stroke-dasharray: 30;
      stroke-dashoffset: 360;
  }
  100% {
      stroke-dasharray: 100;
      stroke-dashoffset: 0;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.66);
  z-index: 9999;
  &_spinner {
    
  }
  &__threedots{

  }
  &_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  
  
}

.s0 {
  stroke-dasharray: 100;
  stroke-dashoffset: 0;
  animation: drawCircle 8s ease-in-out infinite;
}

.s0 { fill: none;stroke: #ffffff;stroke-width: 12 } 
.s1 { fill: url(#g1) } 

.typing-demo {
  width: 11ch;
  animation: typing 2s steps(11) infinite;
  white-space: nowrap;
  overflow: hidden;
  font-family: monospace;
  font-size: 1.3rem;
  color: #ffffff;
}

@keyframes typing {
  from {
    width: 0
  }
}