@import "../views/variables.scss";

//contenitore principale del body
body .login-container{
  width: 100%;
  height: 70em;
  background-image: url(../../assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  display: inline-flex;
  align-items: center;
  position:relative;
  font-family: 'Ubuntu-Medium';
  overflow: hidden;
  margin: 0 auto;
  
  h1 {
    font-size: 70px;
    word-wrap: break-word;
    color: var(--white);
    font-weight: 900;
    line-height:79px;
    letter-spacing: 1px;
  } 
    h2 {
    font-family: 'Ubuntu-Bold';
    background: linear-gradient(117deg, var(--BtnPrimaryColor) 0%, var(--BtnSecondaryColor) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px 15px 30px;
    padding: 40px 0px 0px 28px;
    text-align: left;
    font-size:36px;
    font-weight: 900;
    line-height:72px;
    letter-spacing: -0.5px;
    } 
     h3 {
       font-family: 'Ubuntu-Light';
       size: 24px;
       line-height: 1.2;
       letter-spacing: 0.3px;
       text-align: left;
       }
    h4 {
      font-family: 'Ubuntu-Light';
      font-size: 70px;
      color: var(--white);
      line-height: 79px;
      letter-spacing: 1px;
    }
    h5 {
      background: linear-gradient(to bottom, var(--PrimaryLoginColor) 11%, var(--SecondaryLoginColor) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
     }
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: var(--FooterSecondaryColor);
      margin-top: 6px;
     
    }
    /*@media only screen and (min-width: 768px){
      width: 100%;
      height: 100%;
      display:inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 80px;
      .title{
        
      }
      .panel{
       
        
      }*/
     
    
//titolo in h1 a sinistra
.title{
  text-align: left;
  color: var(--white);
  width: 40%;
  margin-bottom: 700px;
  margin-left: 120px;
}
//pannello login e suoi componenti
.panel {
  background-color: var(--white);
  margin-bottom: 300px;
  padding: 0px auto;
  margin-left:60px;
  width: 446px;
  height: 670px;
  border-radius: 8px;
  
  .user-box input {
    width: 300px;
    font-size: 16px;
    border: none;
    border-bottom: 0.5px solid var(--black);
    outline: none;
    background: transparent;
  }
  
  // bottone login, uguale per tutti
  Button {
    width: 103px;
    height:36px;
    border: 0;
    border-radius: 4px;
    background-image: linear-gradient(117deg, #ed3a7f 0%, #ff6033 100%);
    font-family: 'Ubuntu-Medium';
    font-size: 14px;
    box-shadow: none;
    font-weight: 500;
    letter-spacing: 1.25px;
    color: var(--white);
    padding: 8px 16px;
    margin-top: 20px;
    &:hover {
      opacity: 0.9;
      color: var(--white);
    }}
   //effetti focus label di name
    .form-control:focus {
      border-color: none;
      box-shadow: none;
      border-color:var(--black);
     
    }
    .was-validated .form-control:invalid:focus{
      box-shadow: none;
    }


}
//eye password on-off
// .fa.fa-fw.fa-eye.field-icon.toggle-password {
//   color: rgba(0, 0, 0, 0.6);
//   position: relative;
//   right: 20px;
//   top: 10px;
//   z-index: 10;
// }
//footer del pannello login
.foot {
  padding: 30px;
  margin: 20px 0px 10px 0px;
  font-size: 18px;
  line-height: 0.7;
  letter-spacing: 0.5px;
  font-family: 'Ubuntu-Light';
   
    a {
      background: linear-gradient(to bottom, var(--PrimaryLoginColor) 11%, var(--SecondaryLoginColor) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-decoration: none;
      font-weight: 600;
  }
}
//label di name e password
.form-control {
  border-radius: 0;
  margin: 20px 19px 30px 20px;
  font-size: 16px;
  padding: 0px 0px 0px 0px;
  border: hidden;
  border-bottom: 0.5px solid var(--black);
  background: transparent;
  font-family: 'Ubuntu-Regular';
  color: var(--RegistrationPrimaryColor);
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 600;
}
.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip{
  width: 340px;
  height: 32px;
  padding: 20px;
  font-family: 'Ubuntu-Regular';
  font-size: 12px;
  text-align: left;
  font-weight: 600;
  margin: 0px 0px -20px 5px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  
  a{
    color:#3a87a4;
  }
}
//le colonne del form bootstrap per la validation
.input-group {
  width: 332px;
  margin: 0px 0px 0px 16px;
  bottom: 20px;
  left: 20px;
 }
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4){
  margin: 0px 0px 0px 10px;
  top: 20px;
  left: 10px;
 }
label {
  display: flex;
  margin: 20px 0px 10px 56px;
}

.row {
  display: flex;
  width: 100%;}
}

.item-center{
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}