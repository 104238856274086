.CompanyProfile {
  &__MainTitle {
    margin: 0 0 3rem 1.3rem;
    background-image: linear-gradient(
      97deg,
      var(--TitlePrimaryColor) 0%,
      var(--TitleSecondaryColor) 100%
    );
    font-family: Ubuntu-Regular;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.18px;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  /* ##Subtitle## */
  &_subsitle {
    height: 2.5rem;
    flex-grow: 0;
    font-family: Ubuntu-Bold;
    font-size: 1.1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.15px;
    text-align: left;
    color: var(--RegistrationPrimaryColor);
  }
  &_labels {
    height: 1.5rem;
    flex-grow: 0;
    font-family: Ubuntu-Regular;
    font-size: 1rem;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--RegistrationPrimaryColor);
  }
  &_Button_Container {
    display: flex;
    justify-content: flex-end;
    cursor: auto;
    &_button {
      border-radius: 4px;
      background-image: linear-gradient(
        117deg,
        var(--BtnPrimaryColor) 0%,
        var(--BtnSecondaryColor) 100%
      );
      font-family: Ubuntu-Medium;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
      color: var(--white);
      padding: 8px 16px;
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      &:hover {
        opacity: 0.9;
        color: var(--white);
      }
    }
  }
  &_star_symbol {
    color: var(--red);
  }
  &_bottom {
    &_title {
        font-family: Ubuntu-Bold;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.15px;
        text-align: left;
        color: var(--RegistrationPrimaryColor);
    }
    &_elements{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* flex-wrap can be deleted. When the page gets smaller it takes them down */
        flex-wrap: wrap;
    }
    &_parag{
        font-family: Ubuntu-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
    }
    &_span{
        font-family: Ubuntu-Regular;
        font-weight: 1000;
    }
  }
}


.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: none;
}
/* To change the color of the input boxes. Otherwise, background comes as default color. */
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: rgb(168, 168, 168);
  /* background-image: none; */
}
/* Emails give error if they are wrong.This code takes the error part on the right. */
.Form__control_feedback {
  text-align: left;
}

/* to delete the '!' sign at the and of the each input column. */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
/* To delete the '!' sign at the end of the options column. */
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  background-image: none;
}
/* To delete the sign from the option after it is done correctly */
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  background-image: none;
}
/* to delete the box-shadow */
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: rgb(168, 168, 168);
  box-shadow: none;
}
/* Box-shadow change */
.form-control:focus {
  border-color: var(--BtnPrimaryColor);
  box-shadow: 0 0 0 3px rgba(255, 211, 198, 0.38);
}
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}

/* Override bootstrap green switch/toggle on validation */

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--BtnPrimaryColor);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--BtnPrimaryColor);
}
.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--BtnPrimaryColor);
}